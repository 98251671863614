import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from '../../Networking/Networkingutils'
import moment from "moment";
import { getCookie } from '../../Helper/CookieHelper'
import errorMessage from '../../Helper/ErrorMessage';
import { CircleToBlockLoading } from 'react-loadingg';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { CsvBuilder } from 'filefy';

const token = getCookie("bb_ts_token");
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class CarryForward extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            tabledata: [],
            openDialogue: false,
            user_details: null,
            annual_balance: "",
            isLoading: true,
            lookup: [],
            username: [],
            usersData: [],
        }
    }

    componentDidMount() {
        this.fetchtabledata()
    }

    fetchData(data) {
        var startDate = moment().startOf('year').format('DD-MM-YYYY');
        var endDate = moment().endOf('year').format('DD-MM-YYYY');

        Service.get(`/bb/api/lms/leaveinfo/cumulative/?start=${startDate}&end=${endDate}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var users = [];
                for (let i = 0; i < res.data.lms.length; i++) {
                    const element = res.data.lms[i];
                    var is_exist = false
                    for (let j = 0; j < data.length; j++) {
                        const element1 = data[j];
                        if (element.user_details === element1.uid) {
                            is_exist = true
                        }
                    }
                    if (!is_exist) {
                        users.push(element)
                    }
                }
                // console.log(users)
                this.setState({
                    usersData: users,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchtabledata() {
        const lookupObj = {};

        Service.get(`/bb/api/lms/carryforward/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    lookupObj[element.year] = element.year;
                    element.user_name = element.user_data.last_name + ', ' + element.user_data.first_name;
                    element.update_on = moment(element.updated_on).format('DD/MM/YYYY')
                }
                this.fetchData(res.data);

                this.setState({
                    data: res.data,
                    isLoading: false,
                    lookup: lookupObj,
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }
    yearCheck() {
        var year = moment().format('YYYY');
        return (year === "2021")
    }

    handleUserChange = (event, values) => {
        if (values !== null && values !== undefined) {
            this.setState({ user_details: values })
        }
        else {
            this.setState({ user_details: null })
        }
    }

    handleDataChange(e) {
        if (e.target.id === 'annual_balance') {
            this.setState({ annual_balance: e.target.value })
        } else {
            return null
        }
    }

    userCheck(datas) {

        if (datas.office_loc === 0) {
            return datas.last_name + ", " + datas.first_name + " (UK) ";

        } else {
            return datas.user_info.last_name + ", " + datas.user_info.first_name + " (IND) ";
        }
    }

    openDialog() {
        this.setState({ openDialogue: true, user_details: null, annual_balance: "", })
    }
    postOps(e) {

        var fields = "";

        if (this.state.user_details === null) {
            fields += "User";
        } if (!this.state.annual_balance) {
            fields += (!fields) ? "Annual Balance" : ",Annual Balance";
        }

        if (fields) {
            this.props.enqueueSnackbar(fields + ' is Empty!', {
                variant: 'warning'
            });
        } else {
            const data = {
                uid: this.state.user_details.user_details,
                annual_balance: this.state.annual_balance,
                updated_on: new Date()
            }
            Service.post('/bb/api/lms/carryforward/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    var prev_data = this.state.data;
                    const lookupObj = {};
                    if (res.data) {
                        lookupObj[res.data.year] = res.data.year;
                        res.data.user_name = res.data.user_data.last_name + ', ' + res.data.user_data.first_name;
                        res.data.update_on = moment(res.data.updated_on).format('DD/MM/YYYY');
                    }
                    prev_data.push(res.data)
                    this.setState({
                        data: prev_data,
                        lookup: lookupObj,
                        openDialogue: false
                    })
                    this.props.enqueueSnackbar('User Added Successfully', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    console.log(error)
                    errorMessage(this.props, error)
                });
        }
    }
    putOps(data) {
        Service.put('/bb/api/lms/carryforward/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.props.enqueueSnackbar('User Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error);
                this.fetchtabledata();
            });
    }

    editcheck(data) {
        if (data.year === 2020 || 2021) {
            return true
        } else if (data.year === moment().subtract(1, 'year').calendar()) {
            return false
        } else {
            return false
        }
    }

    render() {

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false })
        };

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    // console.log(rowData[columnDef.field])
                    return columnDef.render ? columnDef.render(rowData) : rowData[columnDef.field];
                })
            )

            const builder = new CsvBuilder("Carry Forward " + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <CircleToBlockLoading color='#007681' />
                ) : (
                    <MaterialTable
                        title="Carry Forward"
                        columns={[
                            {
                                title: 'User', field: 'user_name', editable: 'never',
                                defaultGroupOrder: 0,
                            },
                            {
                                title: 'Year', field: 'year', editable: 'never',
                                lookup: this.state.lookup,
                            },
                            {
                                title: 'Annual Balance', field: 'annual_balance', grouping: false,
                            },
                            {
                                title: 'Updated On', field: 'update_on', editable: 'never',
                            },

                        ]}

                        data={this.state.data}
                        isLoading={this.state.isLoading}
                        options={{
                            padding: "dense",
                            paging: false,
                            actionsColumnIndex: -1,
                            filtering: true,
                            grouping: true,
                            exportButton: { csv: true },
                            exportCsv: (columns, data) => {
                                exportCsv(columns, this.state.data)
                            },
                        }}
                        actions={[
                            {
                                icon: 'add',
                                tooltip: 'Add user',
                                // hidden: !this.yearCheck(),
                                isFreeAction: true,
                                onClick: (event) => this.openDialog()
                            },
                        ]}
                        // editable={{
                        //     isEditable: (rowData) => this.editcheck(rowData),
                        //     onRowUpdate: (newData, oldData) =>
                        //         new Promise(resolve => {
                        //             setTimeout(() => {
                        //                 resolve();
                        //                 if (!newData.annual_balance) {
                        //                     this.props.enqueueSnackbar('Annual Balance is Empty!', {
                        //                         variant: 'warning'
                        //                     });
                        //                 } else {
                        //                     this.setState(prevState => {
                        //                         const data = [...prevState.data];
                        //                         data[data.indexOf(oldData)] = newData;
                        //                         return { ...prevState, data };
                        //                     });
                        //                     this.putOps(newData)
                        //                 }
                        //             }, 600);
                        //         }),
                        // }}
                    />
                )}
                <Dialog open={this.state.openDialogue} onClose={handleDialogueClose} TransitionComponent={Transition} >
                    <DialogContent>

                        <Box marginTop="30px" fontSize={18} flexDirection="row" display="flex" justifyContent="center" fontWeight="bold">
                            Add User
                        </Box>

                        <Box marginTop="30px" flexDirection="row" display="flex" justifyContent="center">
                            <Autocomplete
                                id="user"
                                options={this.state.usersData}
                                getOptionLabel={(option) => this.userCheck(option)}
                                type="name"
                                style={{ width: 350 }}
                                value={(this.state.user_details)}
                                onChange={this.handleUserChange}
                                renderInput={(params) => <TextField {...params} label="user" variant="outlined" />} />
                        </Box>

                        <Box marginTop="30px" display="flex" justifyContent="center" >
                            <TextField
                                variant="outlined"
                                id="annual_balance"
                                label="Annual Balance"
                                type="number"
                                style={{ width: 350 }}
                                value={this.state.annual_balance}
                                onChange={e => this.handleDataChange(e)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Box display="flex" justifyContent="center" marginTop="10px" marginBottom="10px">

                            <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={e => this.postOps(e)}>Add</Button>

                            <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>Cancel</Button>

                        </Box>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withSnackbar(CarryForward)